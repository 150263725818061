<template>
  <el-container>
    <el-aside class="management">
      <el-autocomplete class="el-input"
        popper-class="my-autocomplete"
        v-model="selected"
        :fetch-suggestions="querySearchForList"
        placeholder="请输入内容"
        suffix-icon="el-icon-search"
        @select="handleSelectForLoad">
        <template slot-scope="{ item }">
          <span class="addr">{{ item.name }}</span>
        </template>
      </el-autocomplete>
      <div class="management-height">
        <vue-scroll>
          <ul class="list">
            <li v-for="(material, index) in materialForList" :key="index" :class="material.id === selectedMaterial.id ? 'active' : ''" @click="changeMaterial(material)">
              <var class="bd"></var>
              <span class="material-name">{{ material.name }}</span>
              <i class="iconfont icon-shanchu" @click="delMaterial(material)"></i>
              <i class="iconfont icon-bianji" @click="edit(material)"></i>
            </li>
          </ul>
        <!-- 找不到相应的收藏夹 -->
        <div class="zsy-wh-100" v-show="materialForList.length < 1">
          <div class="myjg-box flex">
            <div class="myjg">
              <img src="../../assets/images/common/empty/sswjg.svg" alt="找不到相应的收藏夹" width="70">
              <p>找不到相应的收藏夹</p>
            </div>
          </div>
        </div>
        </vue-scroll>
      </div>
      <div class="fix-button">
        <div class="button-group scj">          
          <el-button type="primary" plain icon="iconfont icon-jia" @click="addNewMaterial">新建收藏夹</el-button>
        </div>
      </div>
    </el-aside>
    <div class="material-cont">
      <el-card class="box-card ov-v min-h-600">
        <!-- 空状态 -->
        <el-row class="empty" v-if="!loading.data && data.length < 1">
          <div>
            <img src="@/assets/images/common/empty/scj.svg" width="400" class="img" type="image/svg+xml">
            <p class="fz-20">还没有收藏的信息</p>
            <p>点击 <i class="iconfont icon-shoucang"></i> 收藏信息</p>
          </div>
        </el-row>
        <!--@end 空状态 -->
        <div v-loading="loading.data" v-else>
          <div class="mod-title">收藏列表<var>（共{{ realTotal }}条消息）</var></div>
          <div class="empty hide">
            <div>
              <img src="@/assets/images/common/empty/img_xssj.svg" alt="暂无数据" width="400" class="img" type="image/svg+xml">
              <p>暂无数据</p>
            </div>
          </div>
          <div>
            <div class="box-shadow"></div>
            <div class="clear mar-t-20"></div>
            <div class="xxlb">
              <div class="flex xxlb_tit">
                <div class="f-l">
                  <el-button class="f-l mar-r-20" v-show="!checkbox" @click="checkbox = true">批量选取</el-button>
                  <el-button class="f-l mar-r-20" v-show="checkbox" @click="cancelSelected">取消选取</el-button>
                  <ul v-show="checkbox">
                    <li>
                      <el-checkbox v-model="checkedAll" @change="allChecked">全选</el-checkbox>
                    </li>
                    <li class="cur-p pos-r qp-ydxx">
                      <span @click="showMultiMoveAction">
                        <i class="iconfont icon-yidong"></i>
                        <var>移动信息</var>
                      </span>
                      <div class="box-card ydxx" v-show="multiSelected.visible">
                        <p>移动信息至</p>
                        <el-autocomplete
                          popper-class="my-autocomplete"
                          v-model="multiSelected.selected"
                          :fetch-suggestions="querySearchForMulti"
                          placeholder="请输入内容"
                          suffix-icon="el-icon-search"
                          @select="handleSelectForMulti">
                          <template slot-scope="{ item }">
                            <span class="addr">{{ item.name }}</span>
                          </template>
                        </el-autocomplete>
                        <div class="h-100 mar-t-5">
                          <vue-scroll>
                            <ul>
                              <li @click="addNewMaterial" class="cur-p">
                                <i class="iconfont icon-zengjia"></i>新建收藏夹
                              </li>
                              <li
                                :class="m.id === multiSelected.id ? 'cur-p active' : 'cur-p'"
                                v-for="(m, i) in materialForMulti"
                                :key="i"
                                v-if="selectedMaterial.id !== m.id"
                                @click="multiSelected.id = m.id">
                                <i class="iconfont icon-shoucangjia"></i>{{ m.name }}
                              </li>
                            </ul>
                          </vue-scroll>
                        </div>
                        <div class="button-group">
                          <el-button
                            type="primary"
                            round
                            v-loading="multiSelected.loading"
                            :disabled="multiSelected.loading"
                            @click="multiRemove()">确定</el-button>
                          <el-button round @click="multiSelected.visible = false">取消</el-button>
                        </div>
                      </div>
                    </li>
                    <li class="cur-p" @click="delItems">
                      <i class="iconfont icon-shanchu"></i>
                      <var>删除信息</var>
                    </li>
                  </ul>
                  <span class="export_infor cur-p" @click="exportData"><i class="iconfont icon-daochu"></i>
                  <var>导出信息</var></span>
                </div>
                <el-pagination
                  v-if="total > 0"
                  class="flex-1"
                  layout="prev, slot, next"
                  :current-page="currentPage"
                  :page-size="currentPageSize"
                  :total="total"
                  @current-change="handleCurrentChange">
                  <template v-slot>
                    <span style="margin-right: 0;" class="el-pagination__total">
                      {{ currentPage }}/{{ Math.max(Math.ceil(total / currentPageSize), 1) }}
                    </span>
                  </template>
                </el-pagination>
                <div class="f-r">
                  <el-select v-model="order" placeholder="请选择" class="w-140 f-r rank" @change="orderChange">
                    <el-option
                      v-for="item in orderOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <span class="f-r mar-r-10 generate_briefing cur-p" v-show="hasBriefMode" @click="showBuildBrief">
                    <i class="iconfont icon-shengchengjianbao f-l mar-r-5"></i><var>生成简报</var>
                  </span>
                </div>
              </div>
            </div>
            <div class="xxlb">
              <ul class="mar-t-20">
                <li class="item" v-for="(row, index) in data" :key="index">
                  <el-row class="el-row">
                    <el-col :span="1" v-show="checkbox">
                      <el-checkbox class="mar-t-5" v-model="row.checked" @change="checkChange(row.checked, row.rowkey)"></el-checkbox>
                    </el-col>
                    <el-col :span="23">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="22">
                          <h3 @click="showDetail(row.rowkey)">
                            <el-button type="primary" class="mg" v-if="row.emotion == 'negative'">敏感</el-button>
                            <el-button type="primary" class="fmg" v-else>非敏感</el-button>
                            <el-button plain class="yb hide">一般</el-button>
                            <el-button plain class="zy hide">重要</el-button>
                            <span class="icon-yuanfabiaoqian" v-if="row.category == '微博' && row.isforward == 0">原</span>
                            <span class="icon-zhuanfabiaoqian" v-if="row.category == '微博' && row.isforward == 1">转</span>
                            <a class="title">{{ row.title }}</a>
                          </h3>
                        </el-col>
                        <el-col :span="2">
                          <ul class="ico-list">
                            <li @click="delItem(row.rowkey, index)"><i class="iconfont icon-shanchu"></i></li>
                            <li>
                              <i class="iconfont icon-yidong pos-r" @click="showMoveAction(row)"></i>
                              <div class="box-card ydxx" v-show="row.rowJoinMaterial.visible">
                                <p>移动信息至</p>
                                <el-autocomplete
                                  popper-class="my-autocomplete"
                                  v-model="rowSelected.selected"
                                  :fetch-suggestions="querySearchForRow"
                                  placeholder="请输入内容"
                                  suffix-icon="el-icon-search"
                                  @select="handleSelectForRow">
                                  <template slot-scope="{ item }">
                                    <span class="addr">{{ item.name }}</span>
                                  </template>
                                </el-autocomplete>
                                <div class="h-100 mar-t-5">
                                  <vue-scroll>
                                    <ul>
                                      <li @click="addNewMaterial" class="cur-p"><i class="iconfont icon-zengjia"></i>新建收藏夹</li>
                                      <li
                                        :class="m.id === rowSelected.id ? 'cur-p active' : 'cur-p'"
                                        v-for="(m, i) in materialForRow"
                                        :key="i"
                                        v-if="selectedMaterial.id !== m.id"
                                        @click="rowSelected.id = m.id">
                                        <i class="iconfont icon-shoucangjia"></i>{{ m.name }}
                                      </li>
                                    </ul>
                                  </vue-scroll>
                                </div>
                                <div class="button-group">
                                  <el-button
                                    type="primary"
                                    round
                                    @click="rowMove(row, index)"
                                    :disabled="row.rowJoinMaterial.loading"
                                    v-loading="row.rowJoinMaterial.loading">移动</el-button>
                                  <el-button round @click="row.rowJoinMaterial.visible = false">取消</el-button>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </el-col>
                      </el-row>
                      <p class="summary">{{ row.description }}</p>
                      <div class="clear"></div>
                      <p class="info">
                        <span>媒体类型：{{ row.category }}</span>
                        <span>来源：
                          <var class="source_name"
                            v-bind:title="row.category === '微信' ? row.author : row.source_name">
                            {{ row.category === '微信' ? row.author : row.source_name.length > 5 ? row.source_name.substr(0, 5) + "..." : row.source_name }}
                          </var>
                        </span>
                        <span v-if="row.author">作者：{{ row.author.length >= 1 ? row.author : '--' }}</span>
                        <span>信息地区：{{ msgArea(row) }}</span>
                        <span>发布时间：{{ row.published }}</span>
                      </p>
                      <evaluation :data="row" />
                    </el-col>
                  </el-row>
                </li>
              </ul>
              <div class="page-bottom">
                <el-pagination @size-change="handleSizeChange" :hide-on-single-page="singPage"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[20, 50, 100]"
                  :page-size="currentPageSize"
                  layout="slot, sizes, prev, pager, next, jumper"
                  :total="total">
                  <template v-slot>
                    <span class="el-pager-customer-slot">
                      {{ realTotal > 5000 ? `共${realTotal}条，显示前 5000 条` : `共${realTotal}条` }}
                    </span>
                  </template>
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 重命名收藏夹 -->
    <el-dialog class="Dialog_style1 jxscj" :visible.sync="editVisible" :close-on-click-modal="false">
      <div slot="title" class="mod-title">重命名收藏夹</div>
      <div class="cont">
        <div>
          <p class="fz-14">收藏夹名称</p>
          <el-input class="w-310"
            type="text"
            placeholder="请输入收藏夹名称"
            v-model="editMaterial.name"
            maxlength="10"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <div class="button-group">
        <el-button type="primary" round @click="updateMaterial">保存</el-button>
        <el-button round @click="editVisible = false">取消</el-button>
      </div>
    </el-dialog>
    <!--@end 编辑收藏夹 -->
    <article-detail ref="detail" />
    <new-material ref="addMaterial" />
    <generate-brief-dialog ref="brief" v-on:submit="buildBrief" />
    <confirm ref="confirmDelItem" title="从收藏夹删除" confirm="删除" @must="mustDelItem" />
    <confirm ref="confirmDelItems" title="从收藏夹删除" confirm="删除" @must="mustDelItems" />
    <confirm ref="confirmDelMaterial" title="删除收藏夹" confirm="删除" @must="mustDelMaterial" />
  </el-container>
</template>
<script>
  import {
    items,
    del_item,
    move_item,
    deleteMaterial,
    update as updateMaterial
  } from "@/api/material";
  import newMaterial from '@components/common/new-material.vue';
  import evaluation from "@components/common/evaluation.vue";
  import confirm from "@components/common/confirm.vue";
  import articleDetail from '@components/common/article-detail.vue';
  import generateBriefDialog from '@components/common/generate-brief.vue';
  import { getAuthToken, queryString, formatMsgArea, handleDownload } from "@/utils/helpers";
  import { submit as briefingSubmit } from "@/api/briefing";
  import {mapGetters} from 'vuex';
  const _ = window._;
  export default {
    name: 'material',
    components: {
      generateBriefDialog,
      articleDetail,
      newMaterial,
      evaluation,
      confirm
    },
    data() {
      return {
        editVisible:false,
        editMaterial: {name: ''},
        restaurants: [],
        selected: '',
        orderOptions: [
          {
            value: 'join_desc',
            label: '加入素材时间降序'
          },
          {
            value: 'join_asc',
            label: '加入素材时间升序'
          },
          {
            value: 'pub_desc',
            label: '发布时间降序'
          },
          {
            value: 'pub_asc',
            label: '发布时间升序'
          }
        ],
        loading: {material: false, data: true},
        selectedMaterial: {},
        currentPage: 1,
        currentPageSize: 20,
        order: 'join_desc',
        checkedData: {},
        data: [],
        total: 0,
        realTotal: 0,
        checkbox: false,
        checkedAll: false,
        rowSelected: {id: '', selected: ''},
        multiSelected: {id: '', selected: '', visible: false, loading: false},
        timeoutFlag: null,
        materialForList: [],
        materialForRow: [],
        materialForMulti: [],
      singPage: true
      }
    },
    computed: {
      ...mapGetters([
        'materials',
        'hasBriefMode'
      ])
    },
    watch: {
      materials: {
        handler: function (data) {
          this.upgradeMaterials(data);
        },
        deep: true
      }
    },
    destroyed () {
      if (this.timeoutFlag) {
        clearTimeout(this.timeoutFlag)
      }
    },
    mounted() {
      this.loadMaterial()
    },
    methods: {
      loadMaterial() {
        const _this = this;
        this.loading.material = true;
        this.$store.dispatch('materialList').then((data) => {
          this.loading.material = false;
          this.selectedMaterial = _.head(data);

          // 动态传递
          if (this.$route.params.id !== undefined) {
            const row = _.find(data, (o) => {
              return o.id === _this.$route.params.id;
            });
            if (row !== undefined) this.selectedMaterial = row;
          }

          this.upgradeMaterials(data);
          this.loadData()
        }).catch(err => {
          window.console.error(err);
        })
      },
      upgradeMaterials(data) {
        this.restaurants = data;
        this.materialForList = data;
        this.materialForRow = data;
        this.materialForMulti = data;
      },
      loadData() {
        const _this = this;
        this.loading.data = true;
        let params = {id: this.selectedMaterial.id, page: this.currentPage, size: this.currentPageSize, order: this.order}
        items(params).then(res => {
          if (res.data.state) {
            var result = res.data.data;
            this.checkedData = {}; // 当前页选择
            this.checkedAll = false;
            _(result.data).forEach((item, i) => {
              _this.checkedData[item.rowkey] = false;
              result.data[i].checked = false;
              result.data[i].rowJoinMaterial = {
                visible: false,
                selectedId: '',
                loading: false,
                selected: ''
              };
            });
            this.data = result.data;
            this.total = result.page.total >= 5000 ? 5000 : result.page.total;
            this.realTotal = result.page.total;
            this.loading.data = false;
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(() => {
          this.$message.error("加载素材列表失败，服务错误");
        })
      },
      msgArea(row) {
        return formatMsgArea(row);
      },
      orderChange() {
        this.currentPage = 1;
        this.loadData();
      },
      showDetail(rowkey) {
        this.$refs.detail.showDetail(rowkey);
      },
      delMaterial(material) {
        let textBox = [
          {type: "error", text: "此收藏夹中所有的数据将被删除"},
          {type: "success", text: "使用此收藏夹数据生成的报告将被保留"}
        ];
        this.$refs.confirmDelMaterial.show(textBox, material.id);
      },
      mustDelMaterial(id) {
        deleteMaterial(id).then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            const _this = this;
            const {selectedMaterial} = this;
            this.$store.dispatch('materialList').then(data => {
              if (id === selectedMaterial.id) {
                _this.selectedMaterial = _.head(data);
                _this.loadData();
              }
            });
          } else {
            this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        }).catch(() => {
          this.$message.error("删除用户素材失败，服务错误");
        })
      },
      edit(material) {
        this.editMaterial = JSON.parse(JSON.stringify(material));
        this.editVisible = true;
      },
      updateMaterial() {
        const {name, id} = this.editMaterial;
        if (name.length < 1) {
          this.$message.warning('请输入收藏夹名称');
          return false;
        }
        // 修改编辑
        updateMaterial({id, name}).then(res => {
          if (res.data.state) {
            this.$message.success("修改收藏夹成功");
            this.editVisible = false;
            this.$store.dispatch('materialList');
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(() => {
          this.$message.error("修改收藏夹失败，服务错误")
        })
      },
      exportData() {
        const params = {
          id: this.selectedMaterial.id,
          order: this.order,
          token: getAuthToken()
        };
        const query = decodeURIComponent(queryString(params, true));

        handleDownload(`${window.service.api}/material/items/excel${query}`, 'GET', `${window.$moment().format("YYYY-MM-DD")}.xlsx`, null, this.realTotal)
      },
      showMoveAction(row) {
        row.rowJoinMaterial.visible = !row.rowJoinMaterial.visible;
        if (row.rowJoinMaterial.visible) {
          this.rowSelected = {id: '', selected: ''};
        }
      },
      delItems() {
        const {checkedData, selectedMaterial} = this;
        let ids = [];
        _(checkedData).forEach((v, k) => {
          if (v) ids.push(k);
        });
        if (_.size(ids) < 1) {
          this.$message.warning('请选择要删除的信息');
          return false;
        }
        let textBox = [
          {type: "error", text: "此素材将从收藏夹中被移除"},
          {type: "success", text: "使用此素材生成的报告将被保留"}
        ];
        this.$refs.confirmDelItems.show(textBox, {id: selectedMaterial.id, ids: ids.join(",")});
      },
      mustDelItems(data) {
        const _this = this;
        const {ids} = data;
        del_item(data).then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            // 从选中移除
            _(ids).forEach((id) => {
              _this.checkedData[id] = false;
            });
            this.loadData();
          } else {
            this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        }).catch(() => {
          this.$message.error("删除素材失败，服务错误")
        })
      },
      delItem(rowkey, index) {
        let textBox = [
          {type: "error", text: "此素材将从素材库中被移除"},
          {type: "success", text: "使用此素材生成的报告将被保留"}
        ];
        this.$refs.confirmDelItem.show(textBox, {id: this.selectedMaterial.id, ids: rowkey, index});
      },
      mustDelItem(data) {
        const {id, ids, index} = data;
        del_item({id, ids}).then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.data.splice(index, 1)
          } else {
            this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        }).catch(() => {
          this.$message.error("删除素材失败，服务错误")
        })
      },
      showMultiMoveAction() {
        const {multiSelected, checkedData} = this;
        if (!multiSelected.visible) {
          const ids = [];
          _(checkedData).forEach((v, k) => {
            if (v) ids.push(k);
          });
          if (_.size(ids) < 1) {
            this.$message.warning('请选择要移动的素材');
            return false;
          }
        }
        multiSelected.visible = !multiSelected.visible;
        if (multiSelected.visible) this.multiSelected = {id: '', selected: '', visible: true, loading: false};
      },
      multiRemove() {
        const {checkedData, multiSelected, selectedMaterial} = this;
        const ids = [];
        _(checkedData).forEach((v, k) => {
          if (v) ids.push(k);
        });
        if (_.size(ids) < 1) {
          this.$message.warning('请选择要移动的素材');
          return false;
        }
        if (!multiSelected.id) {
          this.$message.warning('请选择收藏夹');
          return false;
        }
        multiSelected.loading = true;
        move_item({src_id: selectedMaterial.id, dst_id: multiSelected.id, rowkeys: ids.join(',')}).then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '移动成功'
            });
            multiSelected.visible = false;
            multiSelected.loading = false;
            this.loadData();
          } else {
            this.$message.error(res.data.error);
          }
        }).catch((err) => {
          window.console.error(err)
          this.$message.error("移动素材失败， 服务错误");
        })
      },
      rowMove(row, index) {
        const { rowSelected, selectedMaterial } = this;
        if (!rowSelected.id) {
          this.$message.warning('请选择收藏夹');
          return false;
        }
        move_item({src_id: selectedMaterial.id, dst_id: rowSelected.id, rowkeys: row.rowkey}).then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '移动成功'
            });
            row.rowJoinMaterial.visible = false;
            row.rowJoinMaterial.loading = false;
            this.data.splice(index, 1);
            this.loadData();
          } else {
            this.$message.error(res.data.error);
          }
        }).catch((err) => {
          window.console.error(err)
          this.$message.error("移动素材失败， 服务错误");
        })
      },
      showBuildBrief() {
        this.$refs.brief.show(false, true);
      },
      buildBrief(submitObj) {
        const {name} = submitObj;
        if (_.size(name) < 1 || _.size(name) > 10) {
          this.$message.warning('简报名称1~10个字符');
          return false;
        }

        const obj = Object.assign(submitObj, {
          mid: this.selectedMaterial.id,
        });
        briefingSubmit(obj).then(res => {
          this.$refs.brief.loading = false
          if (res.data.state) {
            this.$message.success('简报生成中，跳转至简报查看')
            this.$refs.brief.visible = false
            this.timeoutFlag = setTimeout(() => {
              this.$router.push({name: 'brief', params: {toHistory: true}})
            }, 2000)
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(() => {
          this.$refs.brief.loading = false
          this.$message.error('生成简报错误，服务错误');
        });
      },
      addNewMaterial() {
        this.$refs.addMaterial.show();
      },
      changeMaterial(material) {
        if (this.loading.data) {
          this.$message.warning('请等待收藏列表加载完成');
          return false;
        }
        this.selectedMaterial = material;
        this.loadData();
      },
      checkChange(checked, rowkey) {
        this.checkedData[rowkey] = checked;
        let checkedAllState = true;
        _(this.checkedData).forEach((v, k) => {
          if (!v) checkedAllState = false;
        });
        this.checkedAll = checkedAllState;
      },
      cancelSelected() {
        const _this = this;
        this.checkbox = false;
        this.checkedAll = false;
        var selected = {};
        for (let key in this.checkedData) {
          selected[key] = false;
        }
        this.checkedData = selected;
        _(this.data).forEach((item, i) => {
          _this.data[i].checked = false;
        });
      },
      allChecked(state) {
        const _this = this;
        if (state) {
          this.checkedAll = true;
          this.checkbox = true;
          _(this.data).forEach((item, i) => {
            _this.checkedData[item.rowkey] = true;
            _this.data[i].checked = true;
          });
        } else {
          this.checkedAll = false;
          _(this.data).forEach((item, i) => {
            _this.checkedData[item.rowkey] = false;
            _this.data[i].checked = false;
          });
        }
      },
      handleCurrentChange(page) {
        this.currentPage = page;
        this.loadData();
      },
      handleSizeChange(size) {
        this.currentPageSize = size;
        this.currentPage = 1;
        this.loadData();
      },
      querySearchForList(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        this.materialForList = results;
        cb([]);
      },
      querySearchForMulti(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        this.materialForMulti = results;
        cb([]);
      },
      querySearchForRow(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        this.materialForRow = results;
        cb([]);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.name.toLowerCase().indexOf(queryString.toString().toLowerCase()) !== -1);
        };
      },
      handleSelectForLoad(item) {
        let m = _.find(this.materialForList, (m) => {
          return m.id === item.value;
        });
        if (m !== undefined) {
          this.changeMaterial(m);
          this.selected = item.name;
        }
      },
      handleSelectForRow(item) {
        let m = _.find(this.materialForRow, (m) => {
          return m.id === item.value;
        });
        if (m !== undefined) {
          this.rowSelected.id = m.id;
          this.rowSelected.selected = item.name;
        }
      },
      handleSelectForMulti(item) {
        let m = _.find(this.materialForMulti, (m) => {
          return m.id === item.value;
        });
        if (m !== undefined) {
          this.multiSelected.id = m.id;
          this.multiSelected.selected = item.name;
        }
      }
    }
  }
</script>
<style scoped>
/* 左侧导航 */
.management{width: 220px !important;background:rgba(249,250,254,1);position: fixed;left: 0;bottom:0;;box-shadow:0px 2px 8px 0px rgba(65,70,76,0.07);overflow: hidden;top: 60px;z-index: 100;}
.management .el-input{width:190px;padding:15px;display: block;border-bottom: 1px solid #E5E5E5;}
.management >>> .el-input--small .el-input__inner{border-radius: 15px;}
.list{text-align: left;padding-top: 10px;width: 220px;}
.list li{height:50px;line-height:50px;font-size: 16px;text-align: center;color: #333;font-weight:400;position: relative;}
.list .bd{width: 4px;position: absolute;left: 0;height: 100%;display: none;}
.list .icon-shanchu,.list .icon-bianji{float:right;margin-right:10px;display: none;}
.list .material-name{float:left;width:calc(100% - 30px);overflow: hidden;margin-left:30px;white-space: nowrap;text-overflow:ellipsis;font-size: 14px;text-align: left;}
.list li:hover{cursor: pointer;}
.list li:hover .icon-shanchu,.list li:hover .icon-bianji,.list li.active .iconfont{display: block;}
.list li:hover .material-name,.list li.active .material-name{width:calc(100% - 90px);}
.management-height{height: calc(100% - 180px);}
/* 右侧 */
.material-cont{margin-top:80px;margin-left: 240px;margin-right: 20px;margin-bottom: 20px;width:calc(100% - 240px);min-width:1080px;}
.material-cont .mod-title var{font-weight:400;font-size: 12px;}
/* 收藏列表 */
.material-cont >>> .el-card__body{padding:20px 40px;}
.material-cont .mod-title{margin-left: 0;}
.xxlb .xxlb_tit {font-size: 12px;color:#606266;}
.xxlb .xxlb_tit ul {float: left;margin-top:8px;}
.xxlb .xxlb_tit li {margin-right:20px;float: left;}
.xxlb .xxlb_tit .export_infor {margin-top:8px;float: left;}
.xxlb .xxlb_tit .iconfont {margin-right: 5px;float:left;}
.xxlb .xxlb_tit ul span,.xxlb .xxlb_tit var{float:left;}
.xxlb .flex-1.el-pagination {flex: 1;text-align: center;}
.xxlb .generate_briefing {margin-top:8px;float: right;margin-right: 10px;}
.xxlb .xxlb_tit >>> .el-checkbox__input{float: left;margin-top: 2px;}
.xxlb .xxlb_tit >>> .el-checkbox__inner,.xxlb .xxlb_tit >>> .el-checkbox__label{float: left;}
/* 列表内容区块 */
.xxlb .item {border-top: 1px solid #F2F2F2;padding: 20px 0;}
.item h3 {line-height:24px;}
.item .title {font-weight:600;font-size: 14px;width: calc(100% - 200px);overflow: hidden;white-space: nowrap;cursor: pointer;text-overflow:ellipsis;float: left;}
.item .ico-list > li {float: right;margin-left: 10px;}
.item .summary {font-size: 12px;line-height:20px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient: vertical;-webkit-line-clamp:3;color: #414141;margin-top:13px;}
.item .info {line-height:36px;font-size: 12px;color: #707070;}
.item .info span {margin-right: 20px;}
.xxlb >>> .el-select.rank .el-input__inner{padding:0 8px;}
/* 移动信息弹出框 */
.ydxx{padding:10px;position: absolute;right:0;top:30px;z-index: 10;width:180px;background: #fff;box-shadow: 0px 2px 20px 0px rgba(65, 70, 76, 0.07);}
.ydxx p{line-height: 24px;margin-bottom:5px;}
.ydxx li{line-height:24px;font-size: 12px;padding: 0 10px;width:calc(100% - 20px);}
.ydxx li .iconfont{margin-right:5px;}
.h-100{height:100px;}
.ydxx .button-group{border-top: 1px solid #F2F2F2;margin-top:10px;padding-top:10px;}
.ydxx >>> .el-button{width: 60px;height: 28px;line-height: 28px;}
.list .active, .active{cursor: pointer;}
.list li:hover .icon-bianji,.list li:hover .icon-shanchu,.list .active .bd{display: block;}
.qp-ydxx .ydxx{left:-90px;}
.empty{line-height:30px;text-align: center;}
/* 没有结果 */
.myjg-box{height:100%;justify-content: center;align-items: center;}
.myjg p{line-height: 30px;}
</style>
